.body {
    height: 100%;
}

.container {
    min-height: 100vh;
    background: linear-gradient(180deg, #05272f 0%, #081920 100%);
    padding-top: 44px;
    padding-left: 165px;
    padding-right: 165px;
    @media screen and (max-width: 992px) {
        padding-left: 39px;
        padding-right: 39px;
    }
    @media screen and (max-width: 425px) {
        padding-left: 0;
        padding-right: 0;
    }

    display: flex;
    flex-direction: column;

    .inner_container {
        display: flex;
        flex-direction: column;
        flex: 1;
        position: relative;

        @media screen and (max-width: 425px) {
            max-width: none;
        }

        .logo_container {
            text-align: center;

            img {
                max-width: 352px;
                width: 100%;
                height: auto;
            }
        }

        .text_container {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin-top: 80px;

            @media screen and (max-width: 768px) {
                text-align: center;
            }

            .title {
                font-family: Gilroy, sans-serif;
                font-style: normal;
                font-weight: bold;
                font-size: 64px;
                line-height: 79px;
                letter-spacing: -0.01em;
                color: #fff;
                margin-bottom: 28px;
                @media screen and (max-width: 1440px) {
                    font-size: 52px;
                    line-height: 62px;
                }
                @media screen and (max-width: 425px) {
                    font-size: 40px;
                    line-height: 50px;
                }
            }

            .description {
                font-family: Gilroy, sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 32px;
                line-height: 38px;
                letter-spacing: -0.01em;
                color: #00e2c5;
                margin-bottom: 69px;
                @media screen and (max-width: 1440px) {
                    font-size: 24px;
                    line-height: 27px;
                }
                @media screen and (max-width: 425px) {
                    font-size: 16px;
                    line-height: 19px;
                }
            }

            .link {
                background: linear-gradient(270deg, #ee2a1e 0%, #f68616 100%);
                border-radius: 4px;
                padding: 17px 24px;
                color: #fff;
                font-family: Gilroy, sans-serif;
                font-size: 23px;
                font-style: normal;
                font-weight: 700;
                line-height: 28px;
                letter-spacing: -0.01em;
                text-align: center;
                width: fit-content;

                @media screen and (max-width: 768px) {
                    margin: 0 auto;
                }
                @media screen and (max-width: 425px) {
                    font-size: 23px;
                    line-height: 28px;
                    width: 100%;
                }

                &:hover {
                    background: linear-gradient(
                        135deg,
                        #ee2a1e 0%,
                        #f68616 100%
                    );
                    text-decoration: none;
                }
            }
        }

        .status_code {
            background: linear-gradient(
                180deg,
                rgba(4, 250, 237, 0%) 0%,
                #04faed 155.74%
            );
            background-clip: text;
            -webkit-text-fill-color: transparent;
            font-family: Gilroy, sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 442.165px;
            line-height: 547px;
            text-align: center;
            letter-spacing: -0.01em;
            margin: 0;
            margin-block-start: 0;
            margin-block-end: 0;
            margin-inline-start: 0;
            margin-inline-end: 0;
            @media screen and (min-width: 1440px) {
                position: absolute;
                bottom: 0;
                right: 0;
            }
            @media screen and (max-width: 1440px) {
                font-size: 35vw;
                text-align: center;
            }
            @media screen and (max-width: 425px) {
                font-size: 170px;
                line-height: 210px;
                text-align: center;
            }
            @media screen and (max-width: 992px) {
                font-size: 35vw;
                line-height: 1;
                margin-left: -20px;
            }
        }
    }
}
